@import "~@angular/material/prebuilt-themes/indigo-pink.css";

/* Imported fonts */
@font-face {
  font-family: "BPreplay";
  src: url("./assets/fonts/BPreplay/BPreplay.eot");
  src: url("./assets/fonts/BPreplay/BPreplay.eot?#iefix") format("embedded-opentype"),
  url("./assets/fonts/BPreplay/BPreplay.woff") format("woff"),
  url("./assets/fonts/BPreplay/BPreplay.ttf") format("truetype"),
  url("./assets/fonts/BPreplay/BPreplay.svg#Flaticon") format("svg");
}

/* Colors */
:root {
  --primary: #0074e1;
  --secondary: #f4f3ef;
  --tertiary: #e6e6e6;
  --quaternary: #cfcfca;
  --quinary: #1b1f28;
  --senary: #ffffff;
  --septenary: #eeeeee;
  --octonary: #66615b;
  --success: #4caf50;
  --warning: #f7b733;
  --danger: #dd0330;
}

/* Main Content */
a {
  text-decoration: none;
}

body {
  color: var(--quinary);
  cursor: default;
  font-family: 'BPreplay', sans-serif;
  font-size: 14px;
  height: 100%;
  margin: 0;
}

.container {
  display: flex;
  place-content: space-between;
}

.disabled-input {
  border: 2px solid var(--quaternary);
  background-color: var(--septenary);
  border-radius: 6px;
  height: 32px;
  padding: 2px 0;
  text-indent: 10px;
  width: calc(100% - 4px);
}

.embedded-table {
  width: 100%;
}

.flags {
  > img {
    height: 4vh;
  }

  > img:hover {
    cursor: pointer;
  }

  .english {
    margin-left: 20px;
  }
}

html {
  background-color: var(--secondary);
  height: 100%;
}

input {
  color: var(--quinary);
  font-family: 'BPreplay', sans-serif;
  font-size: 14px;
}

input:focus {
  border-color: var(--primary);
  outline: none;
}

input[type="text"],
textarea {
  text-transform: uppercase;
}

input[type="text"]::placeholder {
  text-transform: none;
}

label {
  color: var(--octonary);
  display: inherit;
  font-weight: 900;
  margin-bottom: 5px;
}

.scroll-table {
  max-height: 310px;
  overflow-y: auto;
}

.spacer {
  flex: 1 1 auto;
}

.title {
  color: var(--primary);
  font-size: 20px;
  font-weight: 900;
  margin-bottom: 10px;

  a {
    font-style: italic;
    text-decoration: var(--primary) underline;
  }

  a:hover {
    cursor: pointer;
  }
}

/* Card */
.card,
.first-card,
.second-card,
.third-card {
  background-color: var(--senary);
  border: 1px solid var(--quaternary);
  padding: 10px;
}

.card-title {
  font-size: 16px;
  font-weight: 900;
}

.no-data-section {
  font-size: 16px;
  text-align: center;

  .mat-icon {
    color: var(--octonary);
    font-size: 30px;
    height: 30px;
    margin: 10px 0;
    width: 30px;
  }
}

.right-section {
  width: 32%;

  > * > div {
    margin-bottom: 20px;
  }
}
