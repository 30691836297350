@import '~@angular/material/theming';

@include mat-core();

$primary: (
  50: #e0eefb,
  100: #b3d5f6,
  200: #80baf0,
  300: #4d9eea,
  400: #2689e6,
  500: #0074e1,
  600: #006cdd,
  700: #0061d9,
  800: #0057d5,
  900: #0044cd,
  A100: #f5f7ff,
  A200: #c2d2ff,
  A400: #8facff,
  A700: #7599ff,
  contrast: (
    50: black,
    100: black,
    200: black,
    300: black,
    400: black,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: black,
    A200: black,
    A400: black,
    A700: white
  )
);

$app-primary: mat-palette($primary, 600);
$app-accent: mat-palette($primary, 600);

$app-theme: mat-light-theme($app-primary, $app-accent, mat-palette($mat-red, 600));

@include angular-material-theme($app-theme);

$app-typography: mat-typography-config(
  $font-family: '"BPreplay", "Helvetica Neue", sans-serif'
);

@include angular-material-typography($app-typography);

$background: map-get($app-theme, background);

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button, .mat-fab, .mat-mini-fab {
  font-weight: 900;
}

.mat-cell, .mat-footer-cell, .mat-header-cell {
  text-align: center !important;
}

.mat-checkbox-label {
  color: var(--quinary);
}

.mat-dialog-actions {
  justify-content: flex-end;
}

.mat-dialog-actions button:first-child {
  margin-right: 5px;
}

.mat-dialog-actions button:last-child {
  margin-left: 5px;
}

.mat-dialog-actions button:not(:first-child):not(:last-child) {
  margin: 0 5px;
}

.mat-dialog-title {
  align-items: center;
  display: flex !important;
  flex-direction: row;
  place-content: center space-between;
}

.mat-divider {
  border-top-color: var(--quaternary);
  border-top-width: 2px !important;
  margin-top: 5px !important;
}

.mat-horizontal-content-container,
.mat-horizontal-stepper-header {
  padding: 0 !important;
}

.mat-horizontal-stepper-content {
  display: flex;
  flex-direction: row;
  place-content: space-between;

  .form-section {
    width: 69%;
  }

  .info-section {
    width: 29%;
  }
}

.mat-horizontal-stepper-header-container {
  pointer-events: none;
}

.mat-icon {
  vertical-align: middle;
}

.mat-icon-button:hover {
  background: mat-color($background, 'hover');
}

.mat-list {
  padding-top: 0 !important;
}

.mat-list .mat-list-item {
  height: auto !important;
  padding-top: 10px !important;
}

.mat-list .mat-list-item .mat-list-item-content {
  padding: 0 !important;
}

.mat-step-header.cdk-keyboard-focused,
.mat-step-header.cdk-program-focused,
.mat-step-header:hover {
  background-color: transparent !important;
}

.mat-step-label {
  font-weight: 900;
}

.mat-stepper-horizontal {
  background-color: transparent;
}

.mat-stepper-horizontal-line {
  margin: 0 !important;
}

.mat-tab-body-wrapper {
  margin-top: 10px;
}
